<template>
    <header class="header">
        <v-app-bar dark>
            <img class="icon-header" :src="headerImage" />
            <v-toolbar-title>{{ headerTitle }}</v-toolbar-title>
            <v-spacer />
            <img class="logo" src="../assets/logo-mark-type.png" />
            <div class="jigu_btn" @click="open" v-if="enabled">
                <img class="jigu" src="../assets/icon_jigu_3x.png" />
            </div>
        </v-app-bar>

        <div v-if="enabled" class="fab-operation" :style="bindStyle">
            <v-dialog
                id="configDialog"
                v-model="configDialog"
                persistent
            >
                <div class="configDialog-dialog">

                    <v-icon @click="close" class="close-icon" color="white" large>mdi-close</v-icon>

                    <!-- CSVダウンロード -->
                    <div class="btn-area">
                        <div class="fab-button">
                            <csv-download-component
                                :propDownloadable="true"
                                :propConfigDialog="config"
                            />
                        </div>
                        <p class="fab-button-label">CSVダウンロード</p>
                    </div>

                    <!-- 音通知 -->
                    <div v-if="enabledRing" class="btn-area -last">
                        <div class="fab-button">
                            <ring-sensor-component
                                :propConfigDialog="config"
                            />
                        </div>
                        <p class="fab-button-label">センサー音通知設定</p>
                    </div>

                </div>
            </v-dialog>
        </div>

    </header>
</template>

<script>
import mixinApp from "../store/mixin.app";
import RingSensorComponent from "../components/RingSensorComponent";
import CsvDownloadComponent from "../components/CsvDownloadComponent";

const vm = {
    mixins: [mixinApp],
    components: {
        RingSensorComponent,
        CsvDownloadComponent
    },
    props: {
        propHeader: null,
    },
    data() {
        return {
            configDialog: false,
        }
    },
    computed: {
        headerTitle() {
            return this.propHeader.title();
        },
        headerImage() {
            return this.propHeader.src();
        },
        header() {
            return this.propHeader;
        },
        config() {

            const ret = {};
            ret.open = () => {
                return this.open();
            };
            ret.close = () => {
                return this.close();
            };
            return ret;
        },
        enabled() {
            const currentRoute = this.$router.currentRoute;
            return this.user && this.user.strategy.enabled() && currentRoute.name !== "area";
        },
        enabledRing() {
            return this.user && this.user.strategy.enabled() && !this.user.isAreaManager();
        },
    },
    methods: {
        open() {
            if (!this.configDialog) {
                this.configDialog = true;
            }
        },
        close() {
            if (this.configDialog) {
                this.configDialog = false;
            }
        }
    },
};

export default vm;
</script>

<style scoped>
@import '../assets/css/header.min.css';
</style>