<template>
    <v-progress-circular
        class="loading"
        v-if="loading"
        indeterminate
        color="white"
        size="100"
        width="10"
    />
</template>

<script>
const vm = {
    props: {
        propLoading: false,
    },
    computed: {
        loading() {
            return this.propLoading;
        },
    },
};

export default vm;
</script>

<style scoped>
.loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
</style>
