<template>
    <v-dialog
        v-model="downloadDialog"
        class="download-dialog"
        persistent
        width="600px"
        scrollable
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="fab-button" v-if="downloadable">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="transparent"
              fab
              :small="isIphone"
              @click="downloadDialog = true"
            >
              <img
                v-if="processing"
                src="../assets/icon-progress-download.png"
              />
              <img v-else src="../assets/icon-download.png" />
            </v-btn>
          </div>
        </template>
        <v-card class="" dark style="height: 300px;">
          <div class="dialog-header">
            <v-layout wrap>
              <v-flex text-left style="padding-top: 0.6em;">
                <span>CSVファイルダウンロード</span>
              </v-flex>
              <v-flex text-right mt-2>
                <v-btn
                  class="dialog-button-close"
                  color="transparent"
                  tile
                  @click="downloadDialog = false"
                >
                  <v-icon color="white" large>mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </div>
          <div class="csv-sub-header" :style="bindStyle">
            <v-layout wrap>
              <v-flex text-right ml-5 my-0>
                <date-picker-component
                  :propDisabled="processing"
                  :propUser="propUser"
                  :propDate="searchDate"
                  propLabel="検索日時"
                  @targetDate="targetDate"
                />
              </v-flex>
              <v-flex text-center>
                <search-period-component
                  :propDisabled="processing"
                  :propSearchPeriod="getSearchPeriod()"
                  @change="changeSearchPeriod"
                />
              </v-flex>
              <v-flex text-center mr-16 my-3 ml-3>
                <v-btn
                  :disabled="processing"
                  class=""
                  color=""
                  tile
                  @click="downloadCsv()"
                >
                  <v-icon color="white" large>mdi-note-plus-outline</v-icon>
                  CSVファイル準備
                </v-btn>
              </v-flex>
            </v-layout>
          </div>
          <div class="csv-sub-header" :style="bindStyle">
            <v-flex text-center>
              <v-btn
                :disabled="!isCreatedCsv || processing"
                class="m-3"
                color=""
                tile
                :href="csvInfoDownloadUrl"
                download
              >
                <span v-if="processing">
                  <v-icon color="white" large>mdi-progress-download</v-icon>
                  CSVファイル作成中
                </span>
                <span v-else>
                  <v-icon color="white" large>mdi-download</v-icon>
                  ダウンロード
                </span>
              </v-btn>
            </v-flex>
          </div>
          <div
            class="dialog-content"
            :key="keyDialogContent"
            style=""
          >
            <v-layout row mx-3>
              <v-flex>
                <v-flex>検索日</v-flex>
                <v-flex>作成開始時間</v-flex>
                <v-flex>有効期限</v-flex>
              </v-flex>
              <v-flex>
                <v-flex>{{ csvInfoSearchDate }}</v-flex>
                <v-flex>{{ csvInfoExecutedDatetime }}</v-flex>
                <v-flex>{{ csvInfoDownloadExpired }}</v-flex>
              </v-flex>
              <v-flex>
                <v-flex>期間</v-flex>
                <v-flex>ファイルサイズ</v-flex>
                <v-spacer></v-spacer>
              </v-flex>
              <v-flex>
                <v-flex>{{ csvInfoSearchPeriod }}</v-flex>
                <v-flex> {{ csvInfoFileSize }} </v-flex>
                <v-spacer></v-spacer>
              </v-flex>
            </v-layout>
          </div>
          <div class="dialog-footer" style="margin-bottom: 0.5em;">
            <v-layout row mx-3>
              <v-flex xs12>有効期限を過ぎると、CSVは削除されます。</v-flex>
              <v-flex xs12>再作成すると、CSVは上書きされます。</v-flex>
            </v-layout>
          </div>
        </v-card>
    </v-dialog>
</template>

<script>
import mixinApp from "../store/mixin.app";
import mixinDetail from "../store/mixin.detail";
import constant from "../store/constant";
import models from "../store/models";
import store from "../store";
import DatePickerComponent from "../components/DatePickerComponent";
import SearchPeriodComponent from "../components/SearchPeriodComponent";

const vm = {
    mixins: [mixinApp, mixinDetail],
    components: {
        DatePickerComponent,
        SearchPeriodComponent,
    },
    props: {
        propDownloadable: false,
        propGetLoading: null,
        propConfigDialog: null
    },
    data() {
        return {
            downloadDialog: false,
            unprocessed: true,
            processing: false,
            processed: false,
            searchDate: null,
            searchPeriod: null,
            keyDialogContent: false,
            unsubscribe: () => {},
        };
    },
    computed: {
        bindStyle() {
            return {
            };
        },
        downloadable() {
            return this.propDownloadable;
        },
   /**
     * CSV情報
     */
    csvInfo() {
      return store.getters[constant.storeDownloadCsvGettersCsvInfo()];
    },
    isCreatedCsv() {
      return this.csvInfoDownloadUrl != null;
    },
    // 以後CSVダウンロードの情報のStore Getter
    /** CSV情報:DownloadUrl */
    csvInfoDownloadUrl() {
      if (this.csvInfo == null || this.csvInfo.downloadUrl == null) {
        return null;
      } else {
        return this.csvInfo.downloadUrl;
      }
    },
    /** CSV情報:SearchDate */
    csvInfoSearchDate() {
      if (this.csvInfo == null || this.csvInfo.condition.searchDate == null) {
        return "-";
      } else {
        const value = this.csvInfo.condition.searchDate;
        const valueDate = constant.toDate(value);
        const valueYmdString = valueDate.toLocaleString(
          "ja",
          constant.toLocaleStringYmdOptions()
        );
        return valueYmdString;
      }
    },
    /** CSV情報:SearchPeriod */
    csvInfoSearchPeriod() {
      if (this.csvInfo == null || this.csvInfo.condition.searchPeriod == null) {
        return "-";
      } else {
        return "直近 " + this.csvInfo.condition.searchPeriod + " 日";
      }
    },
    /** CSV情報:ExecutedDatetime */
    csvInfoExecutedDatetime() {
      if (
        this.csvInfo == null ||
        this.csvInfo.condition.executedDatetime == null
      ) {
        return "-";
      } else {
        const valueDate = constant.toDate(
          this.csvInfo.condition.executedDatetime
        );
        const valueYmdString = valueDate.toLocaleString(
          "ja",
          constant.toLocaleStringDateTimeOptions()
        );
        return valueYmdString;
      }
    },
    /** CSV情報:FileSize */
    csvInfoFileSize() {
      if (this.csvInfo == null || this.csvInfo.fileSize == null) {
        return "-";
      } else {
        const valueSize = this.csvInfo.fileSize / 1000;
        return valueSize.toFixed(1) + " KByte";
      }
    },
    /** CSV情報:DownloadExpired */
    csvInfoDownloadExpired() {
      if (this.csvInfo == null || this.csvInfo.downloadExpired == null) {
        return "-";
      } else {
        const valueDate = new Date(this.csvInfo.downloadExpired);
        //const valueDate = constant.toDate(value);
        const valueYmdString = valueDate.toLocaleString(
          "ja",
          constant.toLocaleStringDateTimeOptions()
        );
        return valueYmdString;
      }
    },
    },
    methods: {
        init() {
            this.unprocessed = true;
            this.processing = false;
            this.processed = false;
            this.searchDate = constant.toUnixTime(constant.today());
            this.searchPeriod = constant.createSearchPeriodDefault();
            this.keyDialogContent = !this.keyDialogContent;
        },
    async downloadCsv() {
      this.unprocessed = false;
      this.processing = true;
      const param = {
        condition: this.createSearchCondition(),
        sensorCount: 0, // ToDo: あとで取得処理を入れる
      };
      await store.dispatch(constant.storeDownloadCsvAsync(), param);

      //
      this.unprocessed = true;

      this.processing = false;
      //this.processed = true;
    },
        getCsvName() {
            return new Date().toLocaleString() + ".csv";
        },
        targetDate(date) {
            this.searchDate = date;
        },
        changeSearchPeriod(searchPeriod) {
            this.searchPeriod = searchPeriod;
        },
        createSearchCondition() {
            const ret = models.createSearchCondition();

            ret.userKind = this.user.userKind;
            ret.searchUnit = constant.searchUnitCsv();
            ret.hierarchyCode = this.user.getCurrentHierarchy().hierarchyCode;
            ret.searchDate = this.searchDate;
            ret.searchPeriod = this.searchPeriod.value;
            ret.executedDatetime = constant.toUnixTime(new Date());

            return ret;
        },
        getSearchPeriod() {
            return constant.searchPeriodDefault();
        },
        saveCsv(csv) {
            // RESTから取得したCSVファイルのデータにはBOMが付加されていないので、クライアント側でUTF-8のBOMを付加します。
            const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
            const blob = new Blob([bom, csv], { type: "text/csv" });
            const link = document.createElement("a");

            link.href = window.URL.createObjectURL(blob);
            link.download = this.getCsvName();
            link.click();
        },
        afterResize() {
            this.checkIphone();
        },
        getDownloadButtonPosition() {
            return this.isIphone ? 20 : 12;
        },
    },
    watch: {
        downloadDialog() {
            if (this.downloadDialog) {
                this.init();
                this.propConfigDialog.close();
            }
        },
    },
    created() {

        this.afterResize();
        this.init();
        this.unsubscribe = store
            .subscribe((mutation, state) => {
                if (mutation.type === constant.storeDownloadCsvAsync()) {
                    this.saveCsv(state.download.csv);
                    this.processing = false;
                    this.processed = true;
                }
            })
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};

export default vm;
</script>

<style scoped>
.progress {
    width: 20vw;
}

.progress span {
    display: inline-block;
    margin: 1vh;
}

.title-header {
  display: block;
  margin: 4vh 0 1vh 0;
  font-size: var(--title-font-size);
}
.title-header > span {
  position: relative;
  vertical-align: middle;
  width: 100%;
  display: inline-block;
  overflow: hidden;
}
.title-header > span::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 100%;
  height: 1px;
  flex-grow: 1;
  background-color: #666;
}
.title-header > span > img {
  width: 36px;
  height: 36px;
  vertical-align: middle;
  margin-right: 0.5em;
}
.title-header > span > label {
  vertical-align: middle;
  padding-right: 1em;
}
.csv-sub-header {
  display: flex;
  align-items: center;
  height: 40px;
  margin-top: 1.5vh;
  margin-bottom: 1.5vh;
  border-bottom: none;
}
.csv-sub-header * {
  font-size: var(--sub-header-font-size);
}
.csv-sub-header .v-btn {
  height: 40px;
  border-radius: 0;
  background: #1e1e1e 0% 0% no-repeat padding-box;
  border: 1px solid #616161;
}

.dialog-button-close {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #00000000;
  box-shadow: none;
  padding: 0 !important;
  min-width: 0 !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
}
.dialog-button-close:hover {
  border-radius: 50%;
}
</style>