<template>
    <div class="time-picker-menu">
        <v-menu
            ref="menu"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="time"
            max-width="290px"
            min-width="290px"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="time"
                    :label="label"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :disabled="disabled"
                ></v-text-field>
            </template>
            <v-time-picker
                class="time-picker"
                v-if="menu2"
                v-model="time"
                full-width
                dark
                scrollable
                color="#f6e314"
                format="24hr"
                :style="bindStyle"
                :allowed-minutes="allowedMinutes"
                @click:minute="$refs.menu.save(time)"
            ></v-time-picker>
        </v-menu>
    </div>
</template>

<script>
import mixinApp from "../store/mixin.app";

const vm = {
    mixins: [mixinApp],
    props: {
        propTime: null,
        propDisabled: null,
        propLabel: null,
    },
    data() {
        return {
            time: this.propTime,
            menu2: false,
        };
    },
    computed: {
        bindStyle() {
            return this.strategy.bindStyle(
                {
                    "--time-picker-title-color": this.getMainBackgroundColor(),
                },
                null,
                true
            );
        },
        label() {
            return this.propLabel;
        },
        disabled() {
            return this.propDisabled;
        }
    },
    methods: {
        allowedMinutes: (v) => v % 5 === 0,
    },
    watch: {
        time() {
            this.$emit("targetTime", this.time);
        },
        propTime() {
            this.time = this.propTime;
        },        
    },
};

export default vm;
</script>

<style>
.time-picker .v-time-picker-title {
    color: var(--time-picker-title-color) !important;
    justify-content: left;
}

.time-picker .v-time-picker-clock__container {
    background-color: var(--time-picker-title-color) !important;
}

.time-picker-menu .v-text-field {
    width: 100px;
}

</style>