<template>
    <v-select
        class="search-period"
        v-model="searchPeriod"
        :items="searchPeriodList()"
        :disabled="disabled"
        @change="change"
        return-object
    />
</template>

<script>
import constant from "../store/constant";

const vm = {
    props: {
        propSearchPeriod: null,
        propDisabled: false,
    },
    data() {
        return {
            searchPeriod: this.propSearchPeriod,
        };
    },
    computed: {
        disabled() {
            return this.propDisabled;
        },
    },
    methods: {
        searchPeriodList() {
            return constant.createSearchPeriodList();
        },
        change(value) {
            // 元々watchで値を変更したら検索、としていたが、検索のトリガーを値の変更でなく、
            // 画面のクリック(検索日数の変更ボタン、カルーセルの横ボタン)に反応するように見直し
            this.$emit("change", value);
        }
    },
    watch: {
        propSearchPeriod() {
            this.searchPeriod = this.propSearchPeriod;
        }
    },
};

export default vm;
</script>

<style scoped>
.search-period {
    max-width: 108px;
    margin-left: 16px;
}
</style>
