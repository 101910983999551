import constant from "./constant";
import models from "./models";

/**
 * データの種類
 * 1:CO2
 * 2:温度
 * 3:湿度
 */
type DataSelectNoType = "0" | "1" | "2";
/** 次に選択されるデータの種類 を配列で保持 */
type DataPriorityType = Array<DataSelectNoType>;
/** データ */
type DataTypeInfo = { selectNo: DataSelectNoType, priority: DataPriorityType };

const MainType = constant.mainType();
const Dig = 1;
const Unknown = "--";
const mixinDetail = {
    props: {
        propDetail: null,
    },
    data() {
        return {
            selectNo: this.getMain(MainType).selectNo,
            priority: this.getMain(MainType).priority,
        };
    },
    computed: {
        detail() {
            return this.propDetail;
        },
        sensor() {
            return this.detail.sensor || models.createDetail();
        },
    },
    methods: {
        dispCo2c() {
            return '<span class="detail_num">' + (!this.detail.isEmpty() && constant.isInteger(this.detail.co2c) ? this.detail.co2c : Unknown) + '</span><span class="detail_unit">ppm</span>';
        },
        dispTemp() {
            return '<span class="detail_num">' + (!this.detail.isEmpty() && constant.isDecimal(this.detail.temp) ? this.detail.temp.toFixed(Dig) : Unknown) + '</span><span class="detail_unit">℃</span>';
        },
        dispHumi() {
            return '<span class="detail_num">' + (!this.detail.isEmpty() && constant.isDecimal(this.detail.humi) ? this.detail.humi.toFixed(Dig) : Unknown) + '</span><span class="detail_unit">%</span>';
        },
        /**
         * メイン表示を取得
         */
        getMain(type) {
            //CO2に強制 
            type = "co2c";

            const ret: DataTypeInfo = { selectNo: null, priority: null };
            ret.selectNo = constant.getMain(type);
            ret.priority = this.getParamType(ret.selectNo).priority;
            return ret;
        },
        /**
         * 全詳細情報
         */
        getDetailParams() {

            const ret = [
                {
                    type: "co2c",
                    priority: [0, 1, 2],
                    disp: (this.detail !== undefined && this.detail !== null) ? this.dispCo2c() : "",
                    mode: {
                        caption: "CO2",
                        class: "icon_co2c",
                        image: constant.setImage("icon_co2c.png"),
                    },
                },
                {
                    type: "temp",
                    priority: [1, 2, 0],
                    disp: (this.detail !== undefined && this.detail !== null) ? this.dispTemp() : "",
                    mode: {
                        caption: "温度",
                        class: "icon_thermometer",
                        image: constant.setImage("icon_thermometer.png"),
                    },
                },
                {
                    type: "humi",
                    priority: [2, 0, 1],
                    disp: (this.detail !== undefined && this.detail !== null) ? this.dispHumi() : "",
                    mode: {
                        caption: "湿度",
                        class: "icon_humidity",
                        image: constant.setImage("icon_humidity.png"),
                    },
                }
            ]
            return ret;
        },
        getStatus(no: number, isUser: boolean, value: number) {

            const type = this.getParamType(no).type;
            //CO2に強制設定
            //const type = "co2c";

            const ret = {
                type: type,
                value: (value === undefined || this.detail !== null) ? this.strategy.toDetail(this.detail)[type] : value,
                infos: {
                    name: "gray",
                    code: constant.gray(),
                    src_black: "",
                    src_color: "",
                    message: null,
                    image: null
                }
            }


            ret.infos.src_black = null;
            ret.infos.src_color = null;
            // 無効な数字ならグレー
            if (ret.value === undefined || ret.value === null || ret.value <= 0) {
                return ret
            }
            // 利用者 red => yellow
            let res;
            if (type == "co2c") {
                res = this.getStatusCo2c(ret.value, isUser);
            } else if (type == "temp") {
                res = this.getStatusTemp(ret.value);
            } else if (type == "humi") {
                res = this.getStatusHumi(ret.value);
            }

            // 利用者かつRedだった場合、Yellowにする ※利用者がわからないので聞く
            // if (ret.color == constant.red() && false) {
            //     ret.color = constant.yellow();
            // }
            ret.infos.name = res.name;
            ret.infos.code = res.code;
            ret.infos.message = res.message;
            ret.infos.image = res.image;
            if (type == "co2c") {
                ret.infos.src_black = constant.setImage("icon-face-" + res.name + "-black.png");
                ret.infos.src_color = constant.setImage("icon-face-" + res.name + ".png");
            }

            return ret;
        },
        //Co2状態
        getStatusCo2c(value: number, isUser: boolean) {

            const ret = { name: null, code: null, message: null, image: null }
            // 1500超過 & 一般利用者でない
            if (value > this.hierarchy.lowerRed && !isUser) {
                ret.name = "red";
                ret.code = constant.red();
                ret.message = "換気をしてください";
                ret.image = constant.setImage("icon_exclamation.png");
                // 1000超過 && 1500以下 & 一般利用者でない | 1000超過 & 一般利用者
            } else if (value > this.hierarchy.lowerYellow) {
                ret.name = "yellow";
                ret.code = constant.yellow();
                ret.message = "そろそろ換気が必要です";
                ret.image = constant.setImage("icon_exclamation.png");
                // 1000以下
            } else if (value <= this.hierarchy.lowerYellow) {
                ret.name = "green";
                ret.code = constant.green();
                ret.message = "";
            }
            return ret;
        },
        //温度状態
        getStatusTemp(value) {

            const ret = { name: null, code: null, message: null, image: null }
            // /**
            //  * 緑19.5～25.5
            //  * 黄17.5～19.5、25.5~28.5
            //  * tempLowerGreen 19.5 [17]
            //  * tempUpperGreen 25.5 [28]
            //  * tempLowerYellow 17.5 [12]
            //  * tempUpperYellow 28.5 [33]
            //  */
            // // 19.5以上 && 25.5以下
            // if (this.hierarchy.tempLowerGreen <= value && this.hierarchy.tempUpperGreen >= value) {

            //     ret.name = "green";
            //     ret.code = constant.green();
            //     ret.message = "";

            //     // 17.5以上 && 19.5未満
            //     // 25.5超過 && 28.5以下
            // } else if (
            //     (this.hierarchy.tempLowerYellow <= value && this.hierarchy.tempLowerGreen > value) ||
            //     (this.hierarchy.tempUpperGreen < value && this.hierarchy.tempUpperYellow >= value)
            // ) {

            //     ret.name = "yellow";
            //     ret.code = constant.yellow();
            //     ret.message = "そろそろ温度調整が必要です";
            //     ret.image = constant.setImage("icon_exclamation.png");

            // } else {

            //     ret.name = "red";
            //     ret.code = constant.red();
            //     ret.message = "温度調整をしてください";
            //     ret.image = constant.setImage("icon_exclamation.png");
            // }

            if (value > 30) { ret.code = "#E6655C"; }
            else if (value > 29) { ret.code = "#E0875E"; }
            else if (value > 28) { ret.code = "#DBA55E"; }
            else if (value > 27) { ret.code = "#D4BE5F"; }
            else if (value > 26) { ret.code = "#C7CF5F"; }
            else if (value > 25) { ret.code = "#A8C961"; }
            else if (value > 24) { ret.code = "#8CC460"; }
            else if (value > 23) { ret.code = "#71BD60"; }
            else if (value > 22) { ret.code = "#61B867"; }
            else if (value > 21) { ret.code = "#60BD7E"; }
            else if (value > 20) { ret.code = "#60C499"; }
            else if (value > 19) { ret.code = "#61C9B6"; }
            else if (value > 18) { ret.code = "#5FC7CF"; }
            else if (value > 17) { ret.code = "#5FAFD4"; }
            else if (value > 16) { ret.code = "#5E94DB"; }
            else if (value > 15) { ret.code = "#5E76E0"; }
            else { ret.code = "#655DD9"; }

            return ret;
        },
        //湿度状態
        getStatusHumi(value) {

            const ret = { name: null, code: null, message: null, image: null }

            // /**
            //  * 緑45.5～65.5
            //  * 黄40.5～45.5、65.5～70.5
            //  * humiLowerGreen 45.5
            //  * humiUpperGreen 65.5
            //  * humiLowerYellow 40.5
            //  * humiUpperYellow 70.5
            //  */
            // // 45.5以上 && 65.5以下
            // if (this.hierarchy.humiLowerGreen <= value && this.hierarchy.humiUpperGreen >= value) {
            //     ret.name = "green";
            //     ret.code = constant.green();
            //     ret.message = "";
            //     // 40.5以上 && 45.5未満
            //     // 65.5超過 && 70.5以下
            // } else if (
            //     (this.hierarchy.humiLowerYellow <= value && this.hierarchy.humiLowerGreen > value) ||
            //     (this.hierarchy.humiUpperGreen < value && this.hierarchy.humiUpperYellow >= value)
            // ) {
            //     ret.name = "yellow";
            //     ret.code = constant.yellow();
            //     ret.message = "そろそろ湿度調整が必要です";
            //     ret.image = constant.setImage("icon_exclamation.png");
            // } else {
            //     ret.name = "red";
            //     ret.code = constant.red();
            //     ret.message = "湿度調整をしてください";
            //     ret.image = constant.setImage("icon_exclamation.png");
            // }

            if (value > 70) { ret.code = "#655DD9"; }
            else if (value > 67.5) { ret.code = "#5E76E0"; }
            else if (value > 65) { ret.code = "#5E94DB"; }
            else if (value > 62.5) { ret.code = "#5FAFD4"; }
            else if (value > 60) { ret.code = "#5FC7CF"; }
            else if (value > 57.5) { ret.code = "#61C9B6"; }
            else if (value > 55) { ret.code = "#60C499"; }
            else if (value > 52.5) { ret.code = "#60BD7E"; }
            else if (value > 50) { ret.code = "#61B867"; }
            else if (value > 47.5) { ret.code = "#71BD60"; }
            else if (value > 45) { ret.code = "#8CC460"; }
            else if (value > 42.5) { ret.code = "#A8C961"; }
            else if (value > 40) { ret.code = "#C7CF5F"; }
            else if (value > 37.5) { ret.code = "#D4BE5F"; }
            else { ret.code = "#DBA55E"; }
            return ret;
        },
        /**
         * 詳細情報
         */
        getParamType(no) {
            return this.getDetailParams()[no];
        },
        /**
         * モード切替ボタン
         */
        getModeButtons(no) {
            const buttons = {};
            const arr = this.priority;
            let priority = 0;
            arr.forEach(iNo => {
                buttons[priority] = this.getParamType(iNo).mode;
                buttons[priority].no = iNo;
                buttons[priority].onoff = (iNo == no) ? true : false;
                priority++;
            });
            return buttons;
        },
        /**
         * ローテーションNo
         */
        getRotationNo(no) {

            const len = this.priority.length - 1;
            //最大までくると0番目に戻す
            if (no >= len) {
                no = no - len;
            } else {
                no += 1;
            }
            return no;
        },
        /**
        * CO2 温度 湿度ローテーション
        * 1 > CO2
        * 2 > 温度
        * 3 > 湿度
        * "" > selectNoのローテーション
        */
        changeStatus(no: DataSelectNoType | "", calkback) {

            if (no !== "") {
                this.selectNo = no;
            } else {
                this.selectNo = this.getRotationNo(this.selectNo);
            }

            if (typeof calkback === "function") {
                calkback(this.selectNo);
            }
            this.priority = this.getParamType(this.selectNo).priority;
        },
    },
};

export default mixinDetail;