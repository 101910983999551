<template>
    <div class="date-picker-menu">
        <v-menu v-model="menu" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date"
                    :label="label"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="date-text"
                    :disabled="disabled"
                    :style="bindStyle"
                ></v-text-field>
            </template>
            <v-date-picker
                class="date-picker"
                v-model="date"
                locale="ja-JP"
                dark
                color="#f6e314"
                :max="getMaxDate()"
                :day-format="(date) => new Date(date).getDate()"
                :style="bindStyle"
                @input="menu = false"
                @change="change"
            ></v-date-picker>
        </v-menu>
    </div>
</template>

<script>
import mixinApp from "../store/mixin.app";
import constant from "../store/constant";

const vm = {
    mixins: [mixinApp],
    props: {
        propDate: null,
        propDisabled: null,
        propLabel: null,
    },
    data() {
        return {
            date: this.formatDate(),
            menu: false,
        };
    },
    computed: {
        bindStyle() {
            return this.strategy.bindStyle(
                {
                    "--date-picker-title-color": this.getMainBackgroundColor(),
                },
                null,
                true
            );
        },
        label() {
            return this.propLabel;
        },
        disabled(){
            return this.strategy.disabled() || this.propDisabled;
        },
    },
    methods: {
        getMaxDate() {
            return constant.today().toISOString().substring(0, 10);
        },
        formatDate() {
            const ret = constant.toDate(this.propDate);

            return ret.toISOString().substring(0, 10);
        },
        change(value) {
            this.$emit(
                "change",
                constant.toUnixTime(Date.parse(value))
            );
        },
    },
    watch: {
        date() {
            this.$emit(
                "targetDate",
                constant.toUnixTime(Date.parse(this.date))
            );
        },
        propDate() {
            this.date = this.formatDate();
        },
    },
};

export default vm;
</script>

<style>
.date-picker .v-date-picker-title {
    color: var(--date-picker-title-color) !important;
}

.date-picker .v-date-picker-header,
.date-picker .v-date-picker-table {
    background-color: var(--date-picker-title-color) !important;
}

.date-picker-menu .v-text-field {
    width: 128px;
}

.date-picker-menu input {
    cursor: var(--cursor);
}
</style>
