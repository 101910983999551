import constant from "./constant";

const mixinSensor = {
    props: {
        propSource: null,
        propGetSearchUnit: null,
    },
    data() {
        return {
            //currentSensor: null,
        };
    },
    methods: {
        transitionSensor(user, summary, sensor, source, canTransition, area, rotationSelectNo) {
            if (!canTransition) {
                return;
            }

            this.$router.push({
                name: "sensor",
                params: {
                    propUser: user,
                    //propSummary: summary,
                    propArea: area,
                    propSensorNumber: sensor.sensorNumber,
                    propSource: source,
                    propGetSearchUnit: this.getSearchUnit,
                    propRotationSelectNo: rotationSelectNo,
                },
                query: this.getQuery(),
            }).catch((error) => console.log(error));
        },
        transitionSource(user, summary, area, rotationSelectNo) {
            this.$router.push({
                name: this.getSource(),
                params: {
                    propUser: user,
                    propSummary: summary,
                    propArea: area,
                    propSensorNumber: this.currentSensor.sensorNumber,
                    propSearchUnit: this.getSearchUnit(),
                    propRotationSelectNo: rotationSelectNo,
                },
                query: this.getQuery(),
            });
        },
        getSearchUnit() {
            return this.propGetSearchUnit ? this.propGetSearchUnit() : constant.searchUnitFloor();
        },
        getSource() {
            return this.propSource ? this.propSource : "floor";
        },
    },
    created() {
        //this.currentSensor = this.propSensor;
    },
};

export default mixinSensor;