<template>
    <v-dialog
        v-model="ringSensorSettingDialog"
        persistent
        width="1400px"
        max-width="85%"
        overlay-color="grey darken-3 dialog-overlay"
        :fullscreen="isIphone"
    >
        <template v-slot:activator="{ on, attrs }" class="fab-button">
            <v-btn
                v-bind="attrs"
                v-on="on"
                color="transparent"
                fab
                :small="isIphone"
                @click="openCardPre()"
            >
                <img
                    v-if="!isEnableRing"
                    src="../assets/icon-co2-alert-bell.png"
                />
                <img
                    v-else-if="isRingSensorListAll"
                    src="../assets/icon-ring-sensor-on.png"
                />
                <img
                    v-else-if="isRingSensorListEmpty"
                    src="../assets/icon-ring-sensor-off.png"
                />
                <img v-else src="../assets/icon-ring-sensor-medium.png" />
                <!--
                <img src="../assets/icon_sound_3x.png" />
                -->

            </v-btn>
        </template>

        <v-card v-if="!visibleSensorRingTable" class="dialog-card">
            <div class="dialog-header">
                <!-- <span>メッセージ</span> -->
            </div>
            <div class="dialog-content iphone-portrait-message">
                <div>音通知設定は横画面でご利用ください</div>
                <div>端末を横向きにしてください</div>
            </div>
            <div class="dialog-footer">
                <v-btn
                    class="dialog-button"
                    color="transparent"
                    tile
                    large
                    @click="ringSensorSettingDialog = false"
                >
                    <v-icon color="white" large> mdi-check </v-icon>
                </v-btn>
            </div>
        </v-card>
        <v-card v-else dark class="sensor-ring-table-card">
            <div class="title-header" :style="bindStyle">

                <v-btn
                    class="dialog-button-close"
                    color="transparent"
                    tile
                    @click="closeDialog()"
                >
                    <v-icon color="white">mdi-close</v-icon>
                </v-btn>
                <span>
                    <img src="../assets/icon-ring-sensor-on.png"/>
                    <label>センサー音通知設定</label>
                </span>
                <div class="sub-header" :style="bindStyle">
                    <v-btn @click="groupOpenAll()" v-if="groupAccordion">
                        <v-icon>mdi-plus</v-icon>
                        <span>全て開く</span>
                    </v-btn>
                    <v-btn @click="groupCloseAll()" v-else>
                        <v-icon>mdi-minus</v-icon>
                        <span>全て閉じる</span>
                    </v-btn>
                    <div class="all-do-not-disturb">
                        <span>お休み一括変更</span>
                        <v-switch
                            class="all-do-not-disturb-item my-0"
                            v-model="allEnableDoNotDisturb"
                            :dense="isIphone"
                            hide-details
                        />
                        <v-text-field
                            class="all-do-not-disturb-time mt-0"
                            label="開始"
                            v-model="selectAllDoNotDisturbTimeStart"
                            type="time"
                            :dense="isIphone"
                            hide-details
                            @change="changeAllDoNotDisturbTimeStart()"
                        />
                        <v-text-field
                            class="all-do-not-disturb-time mt-0"
                            label="終了"
                            v-model="selectAllDoNotDisturbTimeEnd"
                            type="time"
                            :dense="isIphone"
                            hide-details
                            @change="changeAllDoNotDisturbTimeEnd()"
                        />
                        <v-btn @click="changeAllDoNotDisturbTime()">変更</v-btn>
                    </div>
                </div>
            </div>

            <v-card-text
                class="sensor-ring-table-card-text pa-0"
                v-bind:class="{ 'is-iphone': isIphone }"
            >
                <v-data-table
                    v-model="selectedRingSensorList"
                    :headers="headers"
                    :items="ringInfoList"
                    item-key="sensorNumber"
                    show-select
                    disable-pagination
                    hide-default-footer
                    fixed-header
                    group-by="floorName"
                    :dense="isIphone"
                    mobile-breakpoint="0"
                    height="504px"
                    class="sensor-ring-table"
                >
                    <template
                        v-slot:[`group.header`]="{
                            group,
                            items,
                            headers,
                            toggle,
                            isOpen,
                        }"
                    >
                        <th :colspan="headers.length" class="headers-floor" style="border-bottom: thin solid black;">
                            <span>
                                <v-icon
                                    :ref="group"
                                    :data-open="isOpen"
                                    @click="toggleFloorName(toggle, isOpen)"
                                >
                                    {{ isOpen ? "mdi-minus" : "mdi-plus" }}
                                </v-icon>
                                <span>{{ items[0].floorName }}</span>
                            </span>
                        </th>
                    </template>

                    <template v-slot:[`item`]="{ item, isSelected, select }">
                        <tr>
                            <td class="pl-10 pr-1">
                                <v-simple-checkbox
                                    :value="isSelected"
                                    :ripple="false"
                                    color="white"
                                    style="margin: 0px; padding: 0px"
                                    hide-details
                                    @input="select(!isSelected);"
                                />
                            </td>

                            <td class="px-1">
                                <span>{{ item.sensorName }}</span>
                            </td>

                            <td class="px-1 widthMin2">
                                <div class="enable-do-not-disturb-mode-item" v-show="isSelected">
                                    <div class="ring-sensor-condition">
                                        <img
                                            v-if="
                                                ringSensorValid(
                                                    isSelected,
                                                    item
                                                )
                                            "
                                            class="icon-ring-sensor"
                                            src="../assets/icon-ring-sensor-on.png"
                                            :style="bindStyle"
                                        />
                                        <img
                                            v-else-if="
                                                doNotDisturbSensorValid(
                                                    isSelected,
                                                    item
                                                )
                                            "
                                            class="icon-ring-sensor"
                                            src="../assets/icon-ring-sensor-off.png"
                                            :style="bindStyle"
                                        />
                                    </div>
                                    <span>
                                        <v-switch
                                            class="ma-0"
                                            v-model="item.enableDoNotDisturb"
                                            :dense="isIphone"
                                            hide-details
                                        />
                                    </span>
                                </div>
                            </td>

                            <td class="px-1">
                                <div v-show="isSelected">
                                    <v-edit-dialog
                                        v-if="item.enableDoNotDisturb"
                                        :return-value.sync="
                                            item.doNotDisturbTimeStart
                                        "
                                        large
                                    >
                                        {{ item.doNotDisturbTimeStart }}
                                        <template v-slot:input>
                                            <v-text-field
                                                v-model="item.doNotDisturbTimeStart"
                                                type="time"
                                                single-line
                                                hide-details
                                                @change="
                                                    changeDoNotDisturbTimeStart(
                                                        item
                                                    )
                                                "
                                            />
                                        </template>
                                    </v-edit-dialog>
                                </div>
                            </td>

                            <td class="px-1">
                                <div v-show="isSelected">
                                    <v-edit-dialog
                                        v-if="item.enableDoNotDisturb"
                                        :return-value.sync="
                                            item.doNotDisturbTimeEnd
                                        "
                                        large
                                    >
                                        {{ item.doNotDisturbTimeEnd }}
                                        <template v-slot:input>
                                            <v-text-field
                                                v-model="item.doNotDisturbTimeEnd"
                                                type="time"
                                                single-line
                                                hide-details
                                                @change="
                                                    changeDoNotDisturbTimeEnd(item)
                                                "
                                            />
                                        </template>
                                    </v-edit-dialog>
                                </div>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions class="sensor-ring-table-card-actions">
                <v-btn
                    class="dialog-button dialog-button-settingRingSensor"
                    color="transparent"
                    tile
                    @click="settingRingSensor()"
                >
                    更新
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import mixinApp from "../store/mixin.app";
import TimePickerComponent from "../components/TimePickerComponent";
import constant from "../store/constant";

const vm = {
    mixins: [mixinApp],
    components: {
        TimePickerComponent,
    },
    props: {
        propConfigDialog: null
    },
    data() {
        return {
            ringSensorSettingDialog: false,
            headers: [
                {
                    text: "",
                    value: "data-table-select",
                    class: "pl-10 pr-1",
                },
                {
                    text: "センサー名",
                    value: "sensorName",
                    class: "px-1",
                },
                {
                    text: "お休みモード",
                    value: "enableDoNotDisturb",
                    align: "center",
                    class: "px-1 widthMin2",
                },
                { text: "開始", value: "doNotDisturbTimeStart", class: "px-1 widthMin startArrow" },
                { text: "終了", value: "doNotDisturbTimeEnd", class: "px-1 widthMin" },
            ],
            selectedRingSensorList: [],
            selectAllDoNotDisturbTimeStart:
                constant.defaultDoNotDisturbTimeStart(),
            selectAllDoNotDisturbTimeEnd: constant.defaultDoNotDisturbTimeEnd(),
            ringInfoList: [],
            allEnableDoNotDisturb: false,
            groupAccordion: false
        };
    },
    computed: {
        bindStyle() {
            return {
                "--title-font-size":
                    this.isIphone || this.isIphoneByWidth ? "large" : "x-large",
                "--sub-header-font-size":
                    this.isIphone || this.isIphoneByWidth ? "small" : "large",
            };
        },
        visibleSensorRingTable() {
            // iPhone && 縦向き以外の場合、センサ通知設定画面を表示する
            return !(this.isIphoneByWidth && !this.isLandscape);
        },
    },
    methods: {
        ringIconSize() {
            return this.isIphone ? 5 : 3;
        },
        //各開閉ボタン
        toggleFloorName(toggle, isOpen) {

            //開閉
            toggle();
            var minusIconCnt = 0;
            this.groupAccordion = false;

            //minus-iconを監視
            Object.values(this.$refs).forEach((x) => {

                //minus
                if (x.$el.classList.contains("mdi-minus")) {
                    minusIconCnt++;
                }
            });

            //minusを押下時、最後の1個だったら全て開くに変更
            if (isOpen === true && minusIconCnt == 1) {
                this.groupAccordion = true;
            }
        },
        groupCloseAll() {
            // グループをすべて閉じる
            Object.keys(this.$refs)
                .filter(
                    (x) => this.$refs[x] && this.$refs[x].$attrs["data-open"]
                )
                .forEach((x) => {
                    this.$refs[x].$el.click();
                });
            this.groupAccordion = true;
        },
        groupOpenAll() {
            // グループをすべて展開する
            Object.keys(this.$refs)
                .filter((x) => this.$refs[x] && !this.$refs[x].$attrs["data-open"])
                .forEach((x) => {
                this.$refs[x].$el.click();
                });
            this.groupAccordion = false;
        },
        changeAllDoNotDisturbTimeStart() {
            if (!this.selectAllDoNotDisturbTimeStart) {
                this.selectAllDoNotDisturbTimeStart =
                    constant.defaultDoNotDisturbTimeStart();
            }
        },
        changeAllDoNotDisturbTimeEnd() {
            if (!this.selectAllDoNotDisturbTimeEnd) {
                this.selectAllDoNotDisturbTimeEnd =
                    constant.defaultDoNotDisturbTimeEnd();
            }
        },
        changeDoNotDisturbTimeStart(item) {
            if (!item.doNotDisturbTimeStart) {
                item.doNotDisturbTimeStart =
                    constant.defaultDoNotDisturbTimeStart();
            }
        },
        changeDoNotDisturbTimeEnd(item) {
            if (!item.doNotDisturbTimeEnd) {
                item.doNotDisturbTimeEnd =
                    constant.defaultDoNotDisturbTimeEnd();
            }
        },
        ringSensorValid(isSelected, item) {
            // 音が鳴る状態の場合true
            // true:チェックボックスON && お休みモードOFF または チェックボックスON && 現在時刻がお休み時間以外
            if (!isSelected) return false;
            if (item.enableDoNotDisturb) {
                const nowTimeValue = Number(
                    constant.totime(constant.getNowUnixTime()).replace(":", "")
                );
                const doNotDisturbTimeStartValue = Number(
                    item.doNotDisturbTimeStart.replace(":", "")
                );
                const doNotDisturbTimeEndValue = Number(
                    item.doNotDisturbTimeEnd.replace(":", "")
                );
                if (doNotDisturbTimeStartValue < doNotDisturbTimeEndValue) {
                    // start < end なら end<-->start
                    return (
                        nowTimeValue < doNotDisturbTimeStartValue ||
                        nowTimeValue > doNotDisturbTimeEndValue
                    );
                } else {
                    // start >= end なら start<--->2400 と 0<--->end
                    return (
                        nowTimeValue < doNotDisturbTimeStartValue &&
                        nowTimeValue > doNotDisturbTimeEndValue
                    );
                }
            } else return true;
        },
        doNotDisturbSensorValid(isSelected, item) {
            // お休み時間帯の場合true
            // true:チェックボックスON && 現在時刻がお休み時間帯
            if (!(isSelected && item.enableDoNotDisturb)) return false;
            const nowTimeValue = Number(
                constant.totime(constant.getNowUnixTime()).replace(":", "")
            );
            const doNotDisturbTimeStartValue = Number(
                item.doNotDisturbTimeStart.replace(":", "")
            );
            const doNotDisturbTimeEndValue = Number(
                item.doNotDisturbTimeEnd.replace(":", "")
            );
            if (doNotDisturbTimeStartValue < doNotDisturbTimeEndValue) {
                // start < end なら end<-->start
                return (
                    nowTimeValue >= doNotDisturbTimeStartValue &&
                    nowTimeValue <= doNotDisturbTimeEndValue
                );
            } else {
                // start >= end なら start<--->2400 と 0<--->end
                return (
                    nowTimeValue >= doNotDisturbTimeStartValue ||
                    nowTimeValue <= doNotDisturbTimeEndValue
                );
            }
        },

        /**
         * dialog画面を閉じる時の処理(ringInfoList全ての情報を保存)
         */
        settingRingSensor() {
            const sensorRingChangeList = this.ringInfoList.map((x) => ({
                sensorNumber: x.sensorNumber,
                sensorName: x.sensorName,
                enableDoNotDisturb: x.enableDoNotDisturb,
                doNotDisturbTimeStart: x.doNotDisturbTimeStart,
                doNotDisturbTimeEnd: x.doNotDisturbTimeEnd,
                isEnableRing: this.selectedRingSensorList
                    .map((x) => x.sensorNumber)
                    .includes(x.sensorNumber),
            }));
            this.changeAllSensorRingInfo(sensorRingChangeList);
            this.changeAllRingSensor(
                this.selectedRingSensorList.map((x) => x.sensorNumber)
            );

            // dialogを閉じる
            this.closeDialog();
        },
        // dialogを閉じる
        closeDialog() {

           // dialogを閉じる
            this.ringSensorSettingDialog = false;
            // 次回開いたときも開いた状態にする
            this.groupOpenAll();
        },
        /**
         * dialog画面表示時の処理
         */
        openCardPre() {

            if (!this.isEnableRing) {
                //読み込みできていないので、読み込む
                this.readRingSensorListByLocalStorage();
                this.readSensorRingInfoByLocalStorage();
                this.importRingMusic();
            }
            this.allEnableDoNotDisturb = false;
            this.selectedRingSensorList = this.ringSensorList
                .filter((x) => this.sensorNumberList.includes(x))
                .map((x) => ({ sensorNumber: x }));

            // storeから保存された一覧読み込み
            this.ringInfoList = this.sensorList.map((sensor) => {
                const sensorRingInfo = this.sensorRingInfoList.find(
                    (y) => y.sensorNumber == sensor.sensorNumber
                );
                const enableDoNotDisturb = sensorRingInfo
                    ? sensorRingInfo.enableDoNotDisturb
                    : false;
                const doNotDisturbTimeStart = sensorRingInfo
                    ? sensorRingInfo.doNotDisturbTimeStart
                    : constant.defaultDoNotDisturbTimeStart();
                const doNotDisturbTimeEnd = sensorRingInfo
                    ? sensorRingInfo.doNotDisturbTimeEnd
                    : constant.defaultDoNotDisturbTimeEnd();
                return {
                    floorCode: sensor.floorCode,
                    floorName: this.user.hierarchy.floorList.find((x) =>
                        sensor.floorCode.includes(x.floorCode)
                    ).floorName,
                    sensorNumber: sensor.sensorNumber,
                    sensorName: sensor.location,
                    enableDoNotDisturb: enableDoNotDisturb,
                    doNotDisturbTimeStart: doNotDisturbTimeStart,
                    doNotDisturbTimeEnd: doNotDisturbTimeEnd,
                };
            });

            this.ringSensorSettingDialog = true;
        },
        // TODO:個別お休み時間編集で、timepickerを利用する場合に使用
        /**
         * time-pickerで選択したお休み開始時間を画面に反映
         * @param {datetime} time time-pickerで選択したお休み開始時間
         */
        setDoNotDisturbTimeStart(time) {
            this.item.doNotDisturbTimeStart = time;
        },
        /**
         * time-pickerで選択したお休み終了時間を画面に反映
         * @param {datetime} time time-pickerで選択したお休み終了時間
         */
        setDoNotDisturbTimeEnd(time) {
            this.item.doNotDisturbTimeEnd = time;
        },
        /**
         * お休みモードとお休み開始終了時間を一括で変更
         */
        changeAllDoNotDisturbTime() {
            this.ringInfoList
                .filter((x) =>
                    this.selectedRingSensorList
                        .map((x) => x.sensorNumber)
                        .includes(x.sensorNumber)
                )
                .forEach((x) => {
                    x.enableDoNotDisturb = this.allEnableDoNotDisturb;
                    x.doNotDisturbTimeStart =
                        this.selectAllDoNotDisturbTimeStart;
                    x.doNotDisturbTimeEnd = this.selectAllDoNotDisturbTimeEnd;
                });
        },
    },
    watch: {
        ringSensorSettingDialog() {
            if (this.ringSensorSettingDialog) {
                this.propConfigDialog.close();
            }
        },
    },
};

export default vm;
</script>

<style scoped>
.fab-button {
    cursor: pointer;
    height: 8vh;
    width: 8vh;
    margin-top: var(--fab-button-margin-top);
}
.fab-button img {
    height: 6vh;
    width: 6vh;
}
.iphone-portrait-message {
    flex-direction: column;
}
.sensor-ring-table-card {
    overflow: hidden;
}
.sensor-ring-table-card {
    overflow: hidden;
    padding: 0 40px!important;
}
.sensor-ring-table-card span{
    vertical-align: middle!important;
}
.title-header {
    display: block;
    margin: 4vh 0 1vh 0;
    font-size: var(--title-font-size);
}
.title-header > span{
    position: relative;;
    vertical-align: middle;
    width: 100%;
    display: inline-block;
    overflow: hidden;
}
.title-header > span::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 100%;
    height: 1px;
    flex-grow: 1;
    background-color: #666;
}
.title-header > span > img{
    width: 36px;
    height: 36px;
    vertical-align: middle;
    margin-right: .5em;
}
.title-header > span > label{
    vertical-align: middle;
    padding-right: 1em;
}
.sub-header {
    display: flex;
    align-items: center;
    height: 40px;
    margin-top: 3.5vh;
    margin-bottom: 2vh;
}
.sub-header * {
    font-size: var(--sub-header-font-size);
}
.sub-header .v-btn{
    height: 40px;
    border-radius: 0;
    background: #1E1E1E 0% 0% no-repeat padding-box;
    border: 1px solid #616161;
}
.all-do-not-disturb {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-left: auto;
}
.all-do-not-disturb span,
.all-do-not-disturb .v-btn,
.all-do-not-disturb-item {
    margin-left: 0.5vw;
}
.all-do-not-disturb-time {
    margin-left: 0.5vw;
    min-width: 45px;
}

.headers-floor span{
    vertical-align: middle!important;
}
.headers-floor span > span{
    margin-left: .4em;
}
.enable-do-not-disturb-mode-item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.ring-sensor-condition {
    position: absolute;
    margin-left: 1vw;
    right: 1.5vw;
}
.icon-ring-sensor {
    display: flex;
    align-items: center;
    height: 24px;
    width: 24px;
}
.dialog-button-settingRingSensor {
    width: 145px !important;
    height: 50px !important;
    background: #007478 0% 0% no-repeat padding-box !important;
    font-size: 18px !important;
    font-weight: initial !important;
}
.dialog-button-close {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #00000000;
    box-shadow: none;
    padding: 0 !important;
    min-width: 0 !important;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50%;
}
.dialog-button-close:hover {
    border-radius: 50%;
}
.v-btn{
    font-weight: initial !important;
}
.sensor-ring-table-card-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 0;
    padding: 0 !important;
    padding: 40px 0 40px 0!important;
}
</style>
